<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="d-flex my-3 justify-content-start">
        <button class="btn btn-success btn-md" @click="showAddModal">
          <i class="flaticon-add"></i> Add Role
        </button>
      </div>

      <div class="card-body p-0">
        <complete-table :loading="loading" :data="currentRoles">
          <template #header>
            <th style="min-width: 50px" class="pl-7">
              <span class="text-dark-75">ID</span>
            </th>
            <th style="min-width: 300px">Role Name</th>
            <th style="min-width: 100px">Action</th>
          </template>

          <template #defaultBody="{ item: role, i }">
            <tr v-bind:key="i">
              <td class="pl-0 ">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ i + 1 }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ role.name }}
                </span>
              </td>

              <td class="pr-0">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="showEditModal(role)"
                >
                  <i class="flaticon-edit"></i>
                </a>

                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                  @click="showDeleteModal(role)"
                >
                  <i class="flaticon-delete"></i>
                </a>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <b-modal ref="add-Role" hide-footer title="Form Tambah Role">
      <b-form @submit="addRole">
        <div class="d-block text-center">
          <div class="row">
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Role Name*</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Name"
                v-model="form.name"
                required
              ></b-form-input>
            </div>

            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Menu*</label>
              <b-card bg-variant="light">
                <b-card-body
                  style="position:relative; height:300px; overflow-y:scroll;"
                >
                  <v-treeview
                    selectable
                    :items="this.formattedMenu"
                    v-model="selectionMenu"
                    selection-type="leaf"
                  >
                  </v-treeview>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>

        <div class="d-flex mt-2">
          <b-button
            class="btn btn-success mr-2"
            block
            :disabled="loading"
            type="submit"
            >Save</b-button
          >

          <b-button
            class="btn btn-secondary mt-0"
            block
            :disabled="loading"
            @click="closeAddModal"
            >Cancel</b-button
          >
        </div>
      </b-form>
    </b-modal>

    <b-modal ref="edit-role" hide-footer title="Form Ubah Role">
      <b-form @submit="updateRole">
        <div class="d-block text-center">
          <div class="row">
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Role Name*</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Role Name"
                v-model="form.name"
                required
              ></b-form-input>
            </div>

            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Menu*</label>
              <b-card bg-variant="light">
                <b-card-body
                  style="position:relative; height:300px; overflow-y:scroll;"
                >
                  <v-treeview
                    selectable
                    :items="this.formattedMenu"
                    v-model="selectionMenu"
                    selection-type="leaf"
                  >
                  </v-treeview>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>

        <div class="d-flex mt-2">
          <b-button
            class="btn btn-success mr-2"
            block
            :disabled="loading"
            type="submit"
            >Update</b-button
          >

          <b-button
            class="btn btn-secondary mt-0"
            block
            :disabled="loading"
            @click="closeEditModal"
            >Cancel</b-button
          >
        </div>
      </b-form>
    </b-modal>

    <b-modal ref="delete-Role" hide-footer title="Delete Role">
      <div class="d-block text-center mb-5 pb-5 pt-5">
        Are You Sure Want To Delete Role ?
      </div>

      <div class="d-flex mt-5">
        <b-button
          class="btn btn-success mr-2"
          block
          :disabled="loading"
          @click="deleteRole"
          >Delete</b-button
        >

        <b-button
          class="btn btn-secondary mt-0"
          block
          :disabled="loading"
          @click="closeDeleteModal"
          >Cancel</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import { GET_MENU_ALL } from "@/core/services/store/menu.module";

import {
  GET_ROLES,
  POST_ROLE,
  DELETE_ROLE,
  PUT_ROLE,
  GET_ROLE_DETAIL
} from "@/core/services/store/role.module";

import CompleteTable from "../../content/widgets/advance-table/CompleteTable";

export default {
  name: "role",
  components: { CompleteTable },
  data() {
    return {
      filter: {
        Rolename: null,
        name: null
      },
      form: {
        name: null,
        email: null,
        Rolename: null,
        pic: null,
        role: null
      },
      tree: [],
      selectionMenu: []
    };
  },
  computed: {
    ...mapGetters([
      "currentRoleLoading",
      "currentRoles",
      "currentMenuAll",
      "currentRoleDetail"
    ]),

    loading() {
      return this.currentRoleLoading;
    },
    formattedMenu() {
      let menu = [];

      if (arrayMoreThanOne(this.currentMenuAll)) {
        menu = this.currentMenuAll.map(menu => {
          return {
            id: menu.id,
            name: menu.name,
            children: this.subMenu(menu.submenu)
          };
        });
      }

      return menu;
    },
    formattedDefaultMenu() {
      let menu = [];

      if (arrayMoreThanOne(this.currentRoleDetail)) {
        menu = this.currentRoleDetail.map(menu => {
          return {
            id: menu.id,
            name: menu.name,
            children: this.sub(menu.sub)
          };
        });
      }

      return menu;
    }
  },
  methods: {
    subMenu(listSubMenu) {
      let result = [];
      if (arrayMoreThanOne(listSubMenu)) {
        result = listSubMenu.map(menu => {
          return {
            id: menu.id,
            name: menu.name,
            children: this.subMenu(menu.submenu)
          };
        });
      }

      return result;
    },

    sub(listSubMenu) {
      let result = [];
      if (arrayMoreThanOne(listSubMenu)) {
        result = listSubMenu.map(menu => {
          return {
            id: menu.id,
            name: menu.name,
            children: this.subMenu(menu.sub)
          };
        });
      }

      return result;
    },

    showAddModal() {
      this.form = {
        id: null,
        name: null
      };

      this.selectionMenu = [];

      this.$refs["add-Role"].show();
    },

    closeAddModal() {
      this.$refs["add-Role"].hide();
    },

    addRole(event) {
      event.preventDefault();

      let parent = this.getParent();
      this.tree = [...parent, ...this.selectionMenu];

      const clearForm = {
        ...this.form,
        name: this.form.name,
        menu: this.tree
      };

      this.$store.dispatch(POST_ROLE, clearForm).then(() => {
        this.closeAddModal();
      });
    },

    updateRole(event) {
      event.preventDefault();
      let parent = this.getParent();
      this.tree = [...parent, ...this.selectionMenu];

      const clearForm = {
        ...this.form,
        name: this.form.name,
        menu: this.tree
      };

      this.$store.dispatch(PUT_ROLE, clearForm).then(() => {
        this.closeEditModal();
      });
    },

    showEditModal(data) {
      Object.assign(this.form, {
        id: data.id,
        name: data.name
      });

      this.selectionMenu = [];

      this.getRolesById(data.id);

      this.$refs["edit-role"].show();
    },

    closeEditModal() {
      this.$refs["edit-role"].hide();
    },

    showDeleteModal(data) {
      Object.assign(this.form, {
        id: data.id
      });

      this.$refs["delete-Role"].show();
    },

    closeDeleteModal() {
      this.$refs["delete-Role"].hide();
    },

    deleteRole() {
      this.$store
        .dispatch(DELETE_ROLE, {
          id: this.form.id
        })
        .then(() => {
          this.closeDeleteModal();
        });
    },

    getRoles() {
      if (!arrayMoreThanOne(this.currentRoles)) {
        this.$store.dispatch(GET_ROLES);
      }
    },

    getRolesById(id) {
      this.$store
        .dispatch(GET_ROLE_DETAIL, id)
        .then((response) => {
          this.selectionMenu = [];

          response.data.menu?.forEach((menu) => {
            this.setSelectedMenu(menu);
          })
        });
    },

    setSelectedMenu(menu) {
      if (menu.sub?.length > 0) {
        menu.sub.forEach((subMenu) => {
          this.setSelectedMenu(subMenu);
        })
      } else {
        this.selectionMenu.push(menu.id_menu);
      }
    },

    getMenuAll() {
      if (!arrayMoreThanOne(this.currentMenuAll)) {
        this.$store.dispatch(GET_MENU_ALL);
      }
    },

    setDefaultMenu(listMenu) {
      this.selectionMenu = [];

      listMenu.map(p =>
        p.children.map(c => {
          let data = this.selectionMenu.filter(id => id === c.id);
          if (data.length === 0) {
            this.selectionMenu.push(c.id);
          }
        })
      );
    },

    getParent() {
      let listChild = [...this.selectionMenu];
      let result = [];
      if (arrayMoreThanOne(this.currentMenuAll)) {
        listChild.map(child => {
          this.formattedMenu.map((c, index) => {
            let data = c.children.filter(d => d.id === child);
            if (data.length > 0) {
              let a = result.filter(a => a === this.formattedMenu[index].id);
              if (a.length === 0) {
                result.push(this.formattedMenu[index].id);
              }
            }
          });
        });
      }

      return result;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Role" }]);

    this.getRoles();
    this.getMenuAll();

    console.log(this.formattedMenu);
  }
};
</script>

<style></style>
